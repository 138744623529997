// Copyright © 2025 Ory Corp
// SPDX-License-Identifier: Apache-2.0
import { RegistrationFlow } from "@ory/client"
import { useCallback, useEffect, useState } from "react"
import { useFrontendApi } from "./oryFrontendApi"

export const useRegistrationFlow = () => {
  const ory = useFrontendApi()
  const [flow, setFlow] = useState<RegistrationFlow>()

  useEffect(() => {
    ory
      .createBrowserRegistrationFlow()
      .then(({ data: flow }) => {
        flow.ui.nodes = flow.ui.nodes.filter((node) =>
          ["oidc", "default"].includes(node.group),
        )
        setFlow(flow)
      })
      .catch((err) => console.error(err))
  }, [window.location.href])

  return flow
}

export const useLogout = () => {
  const ory = useFrontendApi()

  return useCallback(() => {
    ory
      .createBrowserLogoutFlow()
      .then(({ data }) => {
        const logoutUrl = new URL(data.logout_url)
        logoutUrl.searchParams.append("return_to", window.location.href)
        window.location.href = logoutUrl.toString()
      })
      .catch(console.error)
  }, [ory, window.location.href])
}
